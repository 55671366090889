import React, { Component } from "react";
import { Query, Mutation } from "react-apollo";
import decode from "jwt-decode";
import clsx from "clsx";
import ModamilyAppFormHeader from "../form/ModamilyAppFormHeader";
import ModamilyAppButton from "../common/ModamilyAppButton";
import Badge from "../common/ModamilyAppBadge";

import Queries from "../../apollo/queries/queries";
import Mutations from "../../apollo/mutations/mutations";
import SubscriptionOptions from "../../constants/subscriptionTypes";
import config from "../../config/config";
import client from "../../apollo/client";

import closeImg from "../../resources/images/appAssets/closePurple.png";

import { startSession, startBillingSession } from "../../api/apiCheckout";

import { loadStripe } from "@stripe/stripe-js";

class UpgradeModalButton extends Component {
  render() {
    // prettier-ignore
    const { duration, price, savings, totalCost, onClick, selected } = this.props;
    const classes = {
      wrapper: clsx("upgradeModalButton", selected && "selectedUpgradeButton"),
    };

    let textDuration;
    switch (duration) {
      case 0.25:
        textDuration = "1 week";
        break;
      case 1:
        textDuration = "1 month";
        break;
      default:
        textDuration = `${duration} months`;
    }

    return (
      <div onClick={onClick} className={classes.wrapper}>
        <div className="upgradeModalButtonContainer">
          <p className="upgradeButtonDurationText">{textDuration}</p>
          <p className="upgradeButtonPrice">{`$${price}/${
            duration >= 1 ? "mo" : "wk"
          } ($${totalCost})`}</p>
          {savings && (
            <p className="redText regularWeight">{`${savings} savings`}</p>
          )}
        </div>
      </div>
    );
  }
}

/**
 * @property {Function} closeModal - function used to close the modal
 * @property {Boolean} active - boolean determining if the modal is shown or hidden
 */
export default class ModamilyAppUpgradeModal extends React.Component {
  state = {
    subscription: null,
    valid: false,
    session: null,
  };

  validate = () => {
    let query = this.state.subscriptionQuery;
    if (query.type) {
      this.setState({ valid: true });
    }
  };

  handleToken = (token, upgradeAccount) => {
    // console.log(this.state.subscriptionQuery);
    // console.log('token', token);

    let str = JSON.stringify(token);
    // console.log(str);
    upgradeAccount({
      variables: {
        subType: "" + this.state.subscriptionQuery.type,
        price: "" + this.state.subscriptionQuery.price,
        storeSubscription: "" + this.state.subscriptionQuery.store,
        storeReceipt: "" + str,
      },
    }).then((data) => {
      this.props.closeModal();
      window.location.reload();
    });
  };

  updateSubscription = (sub) => {
    this.setState({ subscriptionQuery: sub }, this.validate);
  };

  createCheckoutSession = async (type) => {
    const user = JSON.parse(localStorage["user"]);
    const session = await startSession(type, user.email);
    if (session.id) {
      this.setState({
        subscription: type,
        session,
      });
    }
  };

  createPortalSession = async () => {
    const portalSession = await startBillingSession();
    if (portalSession.url) {
      window.location.href = portalSession.url;
    }
  };

  goToCheckout = async () => {
    const stripe = await loadStripe(config.stripePublicKey);
    stripe.redirectToCheckout({
      sessionId: this.state.session.id,
    });
  };

  handleCancelSubscription = async () => {
    const response = await client.mutate({
      mutation: Mutations.deleteCustomer,
    });
    const result = decode(response.data);
    console.log(result);
    this.props.closeModal();
  };

  renderUpgradeSubscription = () => {
    const user = JSON.parse(localStorage["user"]);
    let buttons = [];

    for (let index = 0; index < SubscriptionOptions.length; index++) {
      const option = SubscriptionOptions[index];
      let button = (
        <UpgradeModalButton
          key={index}
          {...option}
          selected={this.state.subscription === option.type}
          onClick={() => {
            this.createCheckoutSession(option.type);
          }}
        />
      );
      if (option.type === "1m") {
        buttons.push(
          <Badge
            key={index + "badge"}
            type="text"
            text="Most Popular"
            color="red"
          >
            {button}
          </Badge>
        );
      } else {
        buttons.push(button);
      }
    }

    return (
      <div className="upgradeAccountButtons">
        <div className="upgradeAccountButtonsContainer">{buttons}</div>
        <div className="disclaimer">{this.disclaimer()}</div>
        <div className="upgradeAccountFormActions">
          <div className="upgradeAccountUpgradeButtonContainer">
            <ModamilyAppButton
              text="Upgrade"
              style="orangeGradient"
              disabled={!this.state.session}
              onClick={this.goToCheckout}
            />
          </div>
          <div className="upgradeAccountCancelSubscriptionContainer">
            <ModamilyAppButton text="Cancel" onClick={this.props.closeModal} />
          </div>
        </div>
      </div>
    );
  };

  renderEditSubscription = (subscription, isMember) => {
    let user = JSON.parse(localStorage["user"]);

    return (
      <Mutation mutation={Mutations.upgradeAccount}>
        {(mutation, { loading, error, data }) => {
          if (loading) {
            return null;
          }
          if (error) {
            console.log(error);
          }
          let buttons = [];

          for (let index = 0; index < SubscriptionOptions.length; index++) {
            const option = SubscriptionOptions[index];
            let button = (
              <UpgradeModalButton
                key={index}
                {...option}
                selected={
                  this.state.subscriptionQuery
                    ? this.state.subscriptionQuery.type === option.type
                    : false
                }
                disabled={true}
              />
            );
            if (option.type === subscription.subType) {
              buttons.push(
                <Badge
                  key={index + "badge"}
                  type="text"
                  text="Your Current Plan"
                  color="red"
                >
                  {button}
                </Badge>
              );
            } else {
              buttons.push(button);
            }
          }

          return (
            <div className="upgradeAccountButtons">
              <p style={{ padding: "1rem 1.5rem", fontWeight: "bold" }}>
                Looks like you’re already a paid subscriber.
              </p>
              <div className="upgradeAccountButtonsContainer">{buttons}</div>
              <div className="disclaimer">{this.disclaimer()}</div>
              <div className="upgradeAccountFormActions">
                {isMember && (
                  <div className="upgradeAccountCancelSubscriptionContainer">
                    <ModamilyAppButton
                      text="Cancel your subscription"
                      onClick={(e) => {
                        this.handleCancelSubscription();
                      }}
                    />
                  </div>
                )}

                <div className="upgradeAccountCancelSubscriptionContainer">
                  <ModamilyAppButton
                    text="Manage your subscription"
                    onClick={(e) => {
                      this.createPortalSession();
                    }}
                  />
                </div>
              </div>
            </div>
          );
        }}
      </Mutation>
    );
  };

  disclaimer = () => {
    return (
      <>
        <p>Recurring Billing. Cancel anytime.</p>
        <p>
          Your subscription automatically renews unless you cancel at least 24
          hours before the end of the current period.
        </p>
        <p>
          Your account will be charged for renewal within the 24 hours prior to
          the end of the current period.
        </p>
        <p>
          You can manage your subscription by going to Account Details in
          Settings and then Manage Membership.
        </p>
        <p>
          If you purchased your subscription using ios or Android app, you need
          manage your subscription and switch off auto-renewal by accessing your
          itunes & App Store Account Settings after purchase.
        </p>
        <p>All PURCHASES ARE FINAL</p>
        <p>
          All personal data is handled under the terms and conditions of
          Modamily’s privacy policy.
        </p>
      </>
    );
  };

  render = () => {
    let active = this.props.active;

    return (
      <Query query={Queries.getSubscription}>
        {({ loading, data, error }) => {
          if (loading) {
            return null;
          }
          let subscription = null;
          if (data) {
            // console.log(data);
            let response = decode(data.getSubscription);
            if (response.action === "success") {
              subscription = response.data.subscription;
              let user = JSON.parse(localStorage.getItem("user"));
              user.subscription = subscription;
              localStorage.setItem("user", JSON.stringify(user));
            }
          }

          return (
            <div
              className={
                "modalCurtain" + (active ? " curtainDrag" : " modalHidden")
              }
            >
              <div className="upgradeModalCard">
                <div className="upgradeModalCardHeader">
                  <div
                    className="upgradeModalCloseButton"
                    onClick={this.props.closeModal}
                  >
                    <img className="closeButton" src={closeImg} />
                  </div>
                  <div className="upgradeModalHeaderContent">
                    <ModamilyAppFormHeader text="Upgrade today!" />

                    <p className="greyText semiBoldWeight">
                      PREMIUM SUBSCRIPTION FEATURES
                    </p>
                    <p>See who likes you</p>
                    <p>Advanced search filters</p>
                    <p>Message read reciepts</p>
                    <p>No time limit to message mutual matches</p>
                  </div>
                </div>
                <div className="upgradeModalCardBody">
                  {subscription && subscription.subType.toUpperCase() !== "FREE"
                    ? subscription.storeSubscription === "stripe"
                      ? this.renderEditSubscription(subscription)
                      : this.renderEditSubscription(subscription, true)
                    : this.renderUpgradeSubscription()}
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  };
}
